import React, { memo } from 'react';
import { css } from '@emotion/react';
import FuturesaiLandingFooter from '~/modules/futuresai-static/FuturesaiLandingFooter';
import FuturesaiLandingNav from '~/modules/futuresai-static/FuturesaiLandingNav';
import FixedContacts from '~/modules/futuresai-static/FixedContacts';
import FixedMessenger from '~/modules/futuresai-static/FixedMessenger';
import { useFuturesaiTutorialState } from '~/modules/tutorial/useFuturesaiTutorialState';
import UserAgentWarning from '~/modules/AppLayout/UserAgentWarning';
export const FuturesaiLandingLayout = memo(function Layout(props) {
    return (<useFuturesaiTutorialState.Provider>
      <FuturesaiLandingNav />
      <div css={css `
          position: relative;
          background: white;
          margin-top: 80px;
        `}>
        {props.children}
      </div>
      <FuturesaiLandingFooter />
      <FixedContacts />
      <FixedMessenger />
      <UserAgentWarning />
    </useFuturesaiTutorialState.Provider>);
});
