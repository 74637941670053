import React, { memo } from 'react';
import { css } from '@emotion/react';
import useMedia from '~/hooks/useMedia';
import { AppLink } from '~/components/AppLink';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { MdMenu } from 'react-icons/md';
import { RiArrowDropDownFill } from 'react-icons/ri';
import { Divider, Drawer, IconButton, Popper } from '@mui/material';
import { flex, jc } from '~/modules/AppLayout/FlexGridCss';
import { globalBlue, globalBlueGrey, FUTURESAI_THEME, globalGrey, } from '~/modules/AppLayout/Colors';
import { fontWeight600 } from '~/css/font';
import FuturesaiSideBarItems from '~/modules/futuresai-static/FuturesaiSideBarItems';
import { AppLink2 } from '~/components/AppLink2';
const bigLinkCss = css `
  text-decoration: none;
  ${fontWeight600}
  &,
  &:visited {
    color: white;
  }

  font-size: 1.2em;
  cursor: pointer;
`;
const popperlinkItemCss = css `
  text-decoration: none;
  ${fontWeight600};
  font-size: 17px;
  &,
  &:visited {
    color: ${globalBlueGrey.bg500};
  }
  &:hover {
    color: ${globalBlueGrey.bg800};
  }
  & > p {
    margin: 8px 16px;
    text-align: center;
  }
`;
const normalURLProps = {
    hideIcon: true,
    customizedRootCSS: css `
    &:hover {
      background: ${globalBlueGrey.bg50};
      border-left: ${globalBlue.b800} 3px solid;
    }
    height: 48px;
    ${fontWeight600};
    font-size: 1rem;
    color: ${FUTURESAI_THEME};
  `,
};
export default memo(function FuturesaiLandingNav() {
    const { isPhone, isPc } = useMedia();
    const handleDrawerToggle = () => {
        setOpen(false);
    };
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (drawerOpen) => {
        setOpen(drawerOpen);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentLink, setCurrentLink] = React.useState();
    const handleMouseEnter = (event, id) => {
        setAnchorEl(event.currentTarget);
        setCurrentLink(links.find(link => link.keyPopover === id));
    };
    const handleClose = () => {
        setAnchorEl(null);
        setCurrentLink(undefined);
    };
    return (<div css={css `
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 4;
        ${flex.h.crossCenter};
        ${isPhone ? jc.flexStart : jc.flexEnd}
        border-bottom: 0.5px solid gray;
        background: ${FUTURESAI_THEME};
        height: 80px;
        padding: ${isPhone ? '0 16px' : '0 64px'};
        & > * {
          margin-right: 16px;
        }
      `}>
      {isPc || (<IconButton onClick={() => toggleDrawer(true)}>
          <MdMenu color='white'/>
        </IconButton>)}
      <Drawer variant='temporary' open={open} onClose={handleDrawerToggle} ModalProps={{
            keepMounted: true, // Better open performance on mobile.
        }}>
        <FuturesaiSideBarItems hideTrialButton={true}/>
      </Drawer>
      <AppLink2 css={css `
          margin-right: auto;
          ${bigLinkCss};
          ${flex.h.crossCenter};
        `} href='/futuresai'>
        {isPc && (<img alt='ICON' src='/futuresai/icon.png' css={css `
              width: 32px;
              height: 32px;
              margin-right: 16px;
            `}/>)}
        <p css={css `
            font-size: 1.5em;
            letter-spacing: 2px;
          `}>
          期天大勝
        </p>
      </AppLink2>
      {isPc && (<>
          {links.map((link, index) => (<AppLink key={index} rootCSS={bigLinkCss} href={link.children ? '' : link.url}>
              <div css={css `
                  ${flex.h.crossCenter};
                  cursor: pointer;
                  padding: 4px 8px;
                  &:hover {
                    background: ${globalBlueGrey.bg700};
                    border-radius: 5px;
                  }
                  & > p {
                    margin: 0;
                  }
                `} onClick={e => handleMouseEnter(e, link.keyPopover ?? '')}>
                <p>{link.title}</p>
                {link.children && <RiArrowDropDownFill />}
              </div>
            </AppLink>))}
          {currentLink && (<Popper css={css `
                z-index: 1200;
              `} open={true} anchorEl={anchorEl}>
              <div onMouseLeave={handleClose} css={css `
                  color: black;
                  border: ${globalBlueGrey.bg500} 2px solid;
                  background: ${globalGrey.g200};
                  padding: 0 16px;
                `}>
                {currentLink &&
                    currentLink.children?.map((subLink, subIndex) => (<AppLink2 key={subIndex} css={popperlinkItemCss} href={subLink.url}>
                      <div>
                        {subIndex !== 0 && <Divider variant='fullWidth'/>}
                        <p>{subLink.title}</p>
                      </div>
                    </AppLink2>))}
              </div>
            </Popper>)}
        </>)}
      <UserAvatarAsDialogButton rootCSS={css `
          ${isPhone && ' margin-left: auto;'}
        `}/>
    </div>);
});
const links = [
    {
        title: '產品介紹',
        url: '/futuresai/product-intro/main',
        keyPopover: 'intro',
        children: [
            { title: '期天', url: '/futuresai/product-intro/main' },
            { title: '微股力', url: '/futuresai/product-intro/scantrader' },
        ],
    },
    { title: '即時指標', url: '/futuresai/quote' },
    {
        title: '選擇權',
        url: '/futuresai/opbs',
        keyPopover: 'options',
        children: [
            { title: '選擇權分析', url: '/futuresai/opbs' },
            { title: '選擇權報價', url: '/futuresai/option-price' },
            { title: '選擇權OI觀察', url: '/futuresai/options-oi' },
        ],
    },
    {
        title: '股市儀表',
        url: '/futuresai/screener',
        keyPopover: 'stocks',
        children: [
            { title: '即時排行', url: '/futuresai/ranking-realtime' },
            { title: '權值排行', url: '/futuresai/ranking' },
            { title: '股期排行', url: '/futuresai/stock-futures' },
            { title: '大盤籌碼', url: '/futuresai/daily-chips' },
            { title: '個股篩選', url: '/futuresai/screener' },
            //{ title: '訊號牆', url: '/futuresai/signal-wall' },
            { title: '盤中大單動向', url: '/futuresai/large-trade' },
            { title: '基本面研究', url: '/futuresai/stock-analysis' },
        ],
    },
    {
        title: '市場監控',
        url: '/futuresai/monitors/stock',
        keyPopover: 'monitor',
        children: [
            { title: '權值股監控', url: '/futuresai/monitors/stock' },
            { title: '海期監控', url: '/futuresai/monitors/os-futures' },
            { title: '台股漲跌家數', url: '/futuresai/stock-price-change-distribution' },
            { title: '加密貨幣', url: '/futuresai/crypto' },
            { title: '美股', url: '/futuresai/us-stock' },
        ],
    },
    {
        title: '交易筆記',
        url: '/futuresai/miscellaneous/os-futures-plans',
        keyPopover: 'notes',
        children: [
            { title: '海期規劃', url: '/futuresai/miscellaneous/os-futures-plans' },
            { title: '雜談', url: '/futuresai/miscellaneous/mantra' },
            { title: '系統教學手冊', url: '/futuresai/miscellaneous/user-manual' },
        ],
    },
    {
        title: '統計分析資訊',
        url: '/futuresai/daily-statistics',
        keyPopover: 'statistics',
        children: [
            { title: '當日統計資訊', url: '/futuresai/daily-statistics' },
            { title: '漲跌振幅分佈', url: '/futuresai/kbar-statistics' },
            { title: '走勢軌跡分析', url: '/futuresai/historical-chart' },
            { title: '後勢統計圓餅圖', url: '/futuresai/vix-statistics' },
        ],
    },
    {
        title: '程式策略',
        url: '/futuresai/strategy-overview',
        keyPopover: 'strategy',
        children: [
            { title: '策略總覽', url: '/futuresai/strategy-overview' },
            { title: '動態回測', url: '/futuresai/backtest' },
        ],
    },
];
