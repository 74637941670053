import React, { memo } from 'react';
import { css } from '@emotion/react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { globalGrey } from '~/modules/AppLayout/Colors';
export default memo(function FuturesaiLandingFooter() {
    return (<div css={css `
        ${flex.h.mainCenter};
        padding: 32px 8px;
        background: ${globalGrey.g900};
        color: white;
      `}>
      <div>© 2017-2021 期天資訊有限公司</div>
    </div>);
});
