import { IconButton } from '@mui/material';
import React, { memo } from 'react';
import { css } from '@emotion/react';
import { RiMessengerFill } from 'react-icons/ri';
import { AppLink } from '~/components/AppLink';
import useMedia from '~/hooks/useMedia';
import { globalBlue } from '~/modules/AppLayout/Colors';
export default memo(function FixedMessenger() {
    const { isPhone } = useMedia();
    return (<AppLink target='_blank' rootCSS={css `
        border-radius: 50%;
        position: fixed;
        bottom: ${isPhone ? 8 : 24}px;
        right: ${isPhone ? 0 : 16}px;
      `} href='https://m.me/futures.ai.tw/'>
      <IconButton>
        <RiMessengerFill css={css `
            font-size: ${isPhone ? 2.4 : 3}rem;
            color: ${globalBlue.b400};
            &:hover {
              color: ${globalBlue.bA400};
            }
          `}/>
      </IconButton>
    </AppLink>);
});
