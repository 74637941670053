import React, { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { css } from '@emotion/react';
import { AppLink } from '~/components/AppLink';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { fontWeight600 } from '~/css/font';
import { baseNoLinkCss } from '~/css/noLinkCss';
const noLinkCss = css `
  text-decoration: none;
  ${baseNoLinkCss};
`;
export default memo(function FixedContacts() {
    const { isPc } = useMedia();
    const iconCss = css `
    width: ${isPc ? 48 : 32}px;
  `;
    return (<div css={css `
        ${flex.v.default};
        position: fixed;
        left: ${isPc ? 48 : 8}px;
        top: ${isPc ? '120px' : '32%'};
        z-index: 2;
        & > * {
          margin-bottom: 16px;
          writing-mode: vertical-lr;
          ${fontWeight600};
          text-orientation: mixed;
        }
      `}>
      <AppLink rootCSS={noLinkCss} href='https://lin.ee/xqNsFA1' target='_blank'>
        <img src='/futuresai/index/line.png' alt='contact with Line messager' css={iconCss}/>
      </AppLink>
      <AppLink rootCSS={noLinkCss} href='https://www.youtube.com/channel/UCxn_ZSz7lavsYxzlJKNr-LA' target='_blank'>
        <img src='/futuresai/index/youtube.png' alt='youtube icon' css={iconCss}/>
      </AppLink>
      <AppLink rootCSS={noLinkCss} href='https://www.facebook.com/futures.ai.tw/' target='_blank'>
        <img src='/futuresai/index/facebook.png' alt='facebook icon' css={iconCss}/>
      </AppLink>
      <AppLink rootCSS={noLinkCss} href='https://scantrader.com/u/33798/service' target='_blank'>
        <img src='/futuresai/index/scantrader.png' alt='scantrader icon' css={iconCss}/>
      </AppLink>
    </div>);
});
